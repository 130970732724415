import { useDispatch, useSelector } from "react-redux";
import { projects } from "../../data/dummyData";
import SinglePortifolioCard from "./SinglePortifolioCard";
import { useEffect } from "react";
import { galleryList } from "../../action/galleryAction";
import { Link } from "react-router-dom";
import { BASE_IP } from "../../env";

const PortifolioGallery = ({ flex }) => {
  const dispatch = useDispatch();
  const { loading, error, gallerys } = useSelector(
    (state) => state.GalleryList
  );

  console.log("GalleryList @ news page");

  console.log(gallerys);

  useEffect(() => {
    dispatch(galleryList());
  }, [dispatch]);
  return (
    <div
      className={`${
        flex
          ? "flex-wrap gap-2 flex-center-center"
          : "grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2 md:grid-cols-3"
      }`}
    >
      {gallerys?.map((items) => (
        <div
          key={items.id}
          className="relative flex-1 w-full group basis-[20rem]"
        >
          <div className="relative overflow-hidden">
            <Link className="!opacity-100">
              <img
                src={`${items.photo}`}
                alt=""
                className={`w-full ${
                  !flex && "h-fit md:h-[250px]"
                } object-cover group-hover:scale-125 transition-a`}
              />
            </Link>
            <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
              <h1 className="text-lg font-semibold">{items.title}</h1>
              {/* <p>{number} Poperty</p> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PortifolioGallery;
