import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clientList } from "../../../action/clientAction";
import { BASE_IP } from "../../../env";

const Brands = () => {
  const dispatch = useDispatch();
  const {
    loading: loadingClient,
    error: errorClient,
    client,
  } = useSelector((state) => state.ClientList);

  useEffect(() => {
    dispatch(clientList());
  }, [dispatch]);

  if (loadingClient) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (errorClient) {
    return (
      <div className="text-center py-8">
        <p className="text-red-600 text-lg font-semibold">
          Error fetching client data.
        </p>
      </div>
    );
  }

  if (!client || client.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-600 text-lg font-semibold">
          No client data available.
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-extrabold text-black">Our Clients</h2>
        <p className="text-gray-600 mt-2">Working with Fortune 100+ Clients</p>
      </div>
      <div className="flex flex-wrap justify-center gap-3 md:gap-4 lg:gap-6">
        {client.map((item) => (
          <div key={item.id} className="group">
            <img
              src={`${item.image}`}
              alt={item.name || "Client Logo"}
              className="w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 object-cover rounded-full group-hover:scale-105 transform transition-transform"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brands;
