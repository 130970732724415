import { useEffect } from "react";
import { FiDelete } from "react-icons/fi";
import { BiMenu } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { navLinks } from "../../data/navLinks";
import SingleLink from "./SingleLink";
import {
  uiStore,
  closeDropdown,
  closeSidebar,
  openSidebar,
  toggleDarkMode,
} from "../../features/uiSlice";

const AllNavbar = () => {
  const rootDoc = document.querySelector(":root");
  const { darkMode, isSidebarOpen } = useSelector(uiStore);
  const dispatch = useDispatch();

  // Dark mode toggle
  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  // Store dark mode value to localStorage
  useEffect(() => {
    if (darkMode) rootDoc.classList.add("dark");
    else rootDoc.classList.remove("dark");
    localStorage.setItem("Martvilla-theme-mode", JSON.stringify(darkMode));
  }, [darkMode]);

  const handleClose = (e) => {
    if (!e.target.classList.contains("link")) {
      dispatch(closeDropdown());
    }
  };

  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  return (
    <>
      {/* Social Media and Phone Contact */}
      <div className="fixed top-0 left-0 w-full bg-gradient-to-r from-yellow-300 via-pink-300 to-purple-300 p-2 flex justify-between items-center z-30 shadow-lg">
        <div className="flex ml-4 space-x-4">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-transform transform hover:scale-110"
          >
            <FaFacebook className="text-3xl text-white hover:text-blue-300" />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-transform transform hover:scale-110"
          >
            <FaInstagram className="text-3xl text-white hover:text-pink-300" />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-transform transform hover:scale-110"
          >
            <FaTwitter className="text-3xl text-white hover:text-blue-400" />
          </a>
        </div>
        <div className="text-center text-lg font-bold text-white mx-auto">
          Call us: 022-212-46-99
        </div>
      </div>

      {/* Main Navigation Bar */}
      <div
        className="navbar fixed top-[40px] left-0 w-full h-[80px] bg-white dark:bg-gray-800 shadow-lg backdrop-blur-md z-20 px-4 md:px-8 flex items-center justify-between transition-all duration-300"
        onMouseOver={handleClose}
      >
        {/* Company Name */}
        <div className="flex items-center space-x-2">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src="../../../images/Bori-logo.jpg"
              alt="Borikof Trading Logo"
              className="w-28 h-28 rounded-full border-4 border-yellow-400 shadow-xl transform transition-transform duration-500 hover:scale-110"
            />
            <h1 className="text-4xl font-bold text-blue-500 dark:text-yellow-300">
              Borikof Trading Co.
            </h1>
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="hidden md:flex items-center space-x-1 ml-auto">
          {navLinks.map((link) => (
            <SingleLink {...link} key={link.id} />
          ))}
        </div>

        {/* Mobile Menu Toggle */}
        <div
          className="md:hidden flex items-center space-x-2 cursor-pointer"
          onClick={() => dispatch(openSidebar())}
        >
          <BiMenu className="text-4xl text-yellow-500 hover:text-yellow-300 transition-transform transform hover:scale-110" />
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-black/50 z-50 transition-opacity duration-300 ${
          isSidebarOpen
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
        onClick={handleCloseSidebar}
      >
        <ul
          className={`fixed top-0 left-0 w-80 bg-white dark:bg-gray-800 h-full p-6 transition-transform duration-300 ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex items-center justify-between border-b pb-4 mb-4 dark:border-gray-700">
            <p className="text-2xl font-bold text-yellow-500 dark:text-yellow-300">
              Menu
            </p>
            <div
              className="p-2 cursor-pointer"
              onClick={() => dispatch(closeSidebar())}
            >
              <FiDelete className="text-3xl text-red-500 hover:text-red-400 transition-transform transform hover:scale-110" />
            </div>
          </div>
          <p className="text-2xl font-bold mb-4 text-center text-yellow-500 dark:text-yellow-300">
            Borikof Trading Co.
          </p>
          <div className="flex flex-col items-center space-y-2">
            {navLinks.map(({ id, linkText, url, subLinks }) => (
              <li key={id} className="w-full text-center">
                <NavLink
                  to={url}
                  className="text-xl font-bold dark:text-gray-200 hover:text-yellow-500 dark:hover:text-yellow-400 transition-transform transform hover:scale-110 block"
                  onClick={() => dispatch(closeSidebar())}
                >
                  {linkText}
                </NavLink>
                {subLinks?.map(({ id, linkText, url }) => (
                  <ul key={id} className="mt-1 text-center">
                    <NavLink
                      to={url}
                      className="text-lg font-medium dark:text-gray-400 hover:text-yellow-400 dark:hover:text-yellow-300 transition-transform transform hover:scale-110 block"
                      onClick={() => dispatch(closeSidebar())}
                    >
                      {linkText}
                    </NavLink>
                  </ul>
                ))}
              </li>
            ))}
          </div>
        </ul>
      </div>
    </>
  );
};

export default AllNavbar;
