import { BiBuildingHouse } from "react-icons/bi";
import {
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <div className=" text-black">
      <footer className=" py-4">
        {" "}
        {/* Blue background color */}
        <div className="container mx-auto flex flex-col md:flex-row gap-8">
          {/* 1. Company Logo and Company Name */}
          <div className="flex-1 flex flex-col items-center justify-center md:justify-start mb-8 md:mb-0">
            <Link to="/" className="flex flex-col items-center gap-2">
              <img
                src="../../../images/Bori-logo.jpg"
                alt="Mechot Real Estate Logo"
                className="w-42 h-42 object-contain rounded-full" // Make the image circular
              />
              <h1 className="text-xl font-bold">
                borikof trading share company
              </h1>
            </Link>
          </div>

          {/* 2. About Us */}
          <div className="flex-1 flex flex-col items-center md:items-start mb-8 md:mb-0">
            <h2 className="text-2xl font-bold mb-4">About Us</h2>
            <p className="text-lg text-center md:text-left">
              borikof trading share company is a trusted provider of
              non-emergency medical transportation services. Our mission is to
              ensure that every patient receives safe, timely, and compassionate
              transport to and from their medical appointments.
            </p>
          </div>

          {/* 3. Services Links */}
          <div className="flex-1 flex flex-col items-center md:items-start mb-8 md:mb-0">
            <h2 className="text-2xl font-bold mb-4">Services</h2>
            <ul className="list-none p-0 text-center md:text-left">
              <li className="my-3">
                <Link
                  to="/about-us"
                  className="text-lg text-black hover:text-primary transition duration-300"
                >
                  About Us
                </Link>
              </li>
              <li className="my-3">
                <Link
                  to="/class"
                  className="text-lg text-black hover:text-primary transition duration-300"
                >
                  Class
                </Link>
              </li>

              <li className="my-3">
                <Link
                  to="/blog"
                  className="text-lg text-black hover:text-primary transition duration-300"
                >
                  Blog
                </Link>
              </li>
              <li className="my-3">
                <Link
                  to="/contact"
                  className="text-lg text-black hover:text-primary transition duration-300"
                >
                  Contact
                </Link>
              </li>
              <li className="my-3">
                <Link
                  to="/faqs"
                  className="text-lg text-black hover:text-primary transition duration-300"
                >
                  Faq
                </Link>
              </li>
            </ul>
          </div>

          {/* 4. Contact Information and Social Media */}
          <div className="flex-1 flex flex-col items-center md:items-start">
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <div className="flex flex-col">
              <div className="flex items-center mb-3">
                <FiPhone className="text-lg mr-2" />
                <p className="text-lg">
                  Phone: +2519988407026 or 0222124699
                </p>{" "}
              </div>
              <div className="flex items-center mb-3">
                <FaEnvelope className="text-lg mr-2" />
                <p className="text-lg">Email: borikoftsc@gmail.com</p>
              </div>
              <div className="flex items-center mb-3">
                <FaMapMarkerAlt className="text-lg mr-2" />
                <p className="text-lg">Oromia Adama 04 kebele</p>
              </div>

              <div className="gap-5 my-6 flex justify-center">
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="#">
                    <FiFacebook className="text-white" />
                  </a>
                </div>
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="#">
                    <FaWhatsapp className="text-white" />
                  </a>
                </div>
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="#">
                    <FaInstagram className="text-white" />
                  </a>
                </div>
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="#">
                    <FaTelegram className="text-white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="py-2 mt-3 text-center border-t text-muted border-dark">
        <a href="https://simbatech.et/">
          Created By{" "}
          <span className="text-primary">Simbatech Technology Solution</span> |
          All Rights Reserved
        </a>
      </div>
    </div>
  );
};

export default Footer;
