import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faBath,
  faExpand,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMapMarkerAlt,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { propertiesList } from "../../action/propertiesAction";
import { BASE_IP } from "../../env";

const PortifolioList = ({ flex }) => {
  const dispatch = useDispatch();
  const { loading, error, properties } = useSelector(
    (state) => state.PropertiesList
  );

  useEffect(() => {
    dispatch(propertiesList());
  }, [dispatch]);

  const [showDetails, setShowDetails] = useState(null);

  const handleReadMoreClick = (itemId) => {
    setShowDetails((prevId) => (prevId === itemId ? null : itemId));
  };

  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomToggle = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div
      className={`${
        flex
          ? "flex-wrap gap-4 justify-center items-center"
          : "grid grid-cols-1 gap-6 mt-8 sm:grid-cols-2 md:grid-cols-3"
      }`}
    >
      {properties?.map((items) => (
        <div key={items.id} className="relative w-full group mb-8">
          <div className="relative overflow-hidden rounded-lg shadow-md">
            <div
              className={`relative w-full group ${isZoomed ? "zoomed-in" : ""}`}
            >
              <Link
                to={`/projectdetails/${items.id}`}
                className="block relative"
              >
                <img
                  src={`${items.photo}`}
                  alt={items.title}
                  className="w-full h-[300px] object-cover group-hover:scale-105 transition-transform"
                />

                {/* Zoom Image Icon */}
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                  onClick={handleZoomToggle}
                >
                  <FontAwesomeIcon
                    icon={faSearchPlus}
                    className="text-white text-2xl"
                  />
                </div>
              </Link>

              {/* Location Name Text */}
              <div className="absolute bottom-4 left-4 text-white hover:text-primary">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
                {items.location}
              </div>
            </div>

            <div className="absolute inset-0 px-4 py-3 bg-gradient-to-t from-black/70 text-white transition-opacity opacity-0 group-hover:opacity-100">
              {/* Additional overlay content */}
            </div>
          </div>

          <div className="p-6">
            <div className="flex justify-between">
              {/* Other content on the left side */}
              <h5 className="mb-3 text-lg font-bold text-gray-800 dark:text-white">
                {/* Your Other Content */}
              </h5>

              {/* Phone Call link on the right side */}
              <a
                href="tel:+123456789"
                className="flex items-center text-lg font-extrabold text-orange-500 hover:underline"
              >
                <FontAwesomeIcon icon={faPhone} className="mr-2" />
                Phone Call
              </a>
            </div>

            <h5 className="mb-3 text-lg font-bold text-gray-800 dark:text-white hover:text-primary">
              {items.title}
            </h5>

            {/* Truncated Description */}
            <p className="mb-4 pb-2 text-gray-600 dark:text-gray-400">
              {showDetails === items.id
                ? items.description
                : `${items.description.slice(0, 30)}...`}
            </p>

            {/* Read More/Read Less Button */}
            <button
              onClick={() => handleReadMoreClick(items.id)}
              className="text-lg relative inline-flex items-center justify-center p-2 mb-2 me-2 overflow-hidden font-medium text-white rounded-lg group bg-gradient-to-r from-blue-500 to-blue-700 group-hover:from-orange-400 group-hover:to-orange-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800"
            >
              {showDetails === items.id ? "Read less" : "Read more"}
            </button>

            {/* Full Details Table */}
            {/* {showDetails === items.id && (
              <div className="mt-4 text-gray-600 dark:text-gray-400">
                <table className="w-full bg-white border border-gray-300 dark:border-gray-600 divide-y divide-gray-300 dark:divide-gray-600">
                  <tbody>
                    <p className="mb-4 pb-2 text-gray-600 dark:text-gray-400">
                      {showDetails === items.id
                        ? items.description
                        : `${items.description.slice(0, 50)}...`}
                    </p>
                  </tbody>
                </table>
              </div>
            )} */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PortifolioList;
