import React, { useEffect, useState } from "react";
import AllNavbar from "../components/common/AllNavBar";
import AboutUs from "../components/common/page-componets/AboutUs";
import Vision from "../components/common/page-componets/Vision";
import { useDispatch, useSelector } from "react-redux";
import { staffsList } from "../action/serviceAction";
import { BASE_IP } from "../env";
import Modal from "../components/common/Modal";
// import Modal from "../components/common/Modal"; // Assuming you have a Modal component

const About = () => {
  const dispatch = useDispatch();
  const { staff, error } = useSelector((state) => state.StaffList);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(staffsList());
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  if (loading) {
    return <div className="text-center py-20">Loading...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-20 text-red-500">Error loading data!</div>
    );
  }

  return (
    <>
      <AllNavbar />

      {/* Gradient Background Section */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <div className="text-center mb-10">
          <h2 className="text-4xl font-extrabold text-teal-600 mb-4">
            About Us
          </h2>
        </div>
      </div>

      <div className="px-[3%] md:px-[6%] mt-8 lg:mt-2">
        <AboutUs />

        {/* Company Information Section */}
        <div className="bg-gray-50 py-16 mt-16 px-[3%] md:px-[6%]">
          <div className="max-w-5xl mx-auto text-gray-800">
            <h2 className="text-5xl font-extrabold text-center text-teal-700 mb-8">
              Borikof Trading Share Company
            </h2>
            <p className="text-lg mb-6">
              As the name Borikof implies from its Oromic root, “for my tomorrow
              / for my descendants”, the company was established to set a
              profound foundation for tomorrow.
            </p>
            <p className="text-lg mb-6">
              Borikof Trading Share Company is a business organization with full
              legal standing, established in 2012 EC with a starting capital of
              Eth Birr 10,570,000.00 (ten million five hundred seventy thousand
              birr). The company was established to address significant
              community needs, including education, public health, and supplies.
            </p>
            <p className="text-lg mb-6">
              The company embarked on its mission during the global outbreak of
              the Corona – Covid 19 pandemic. Despite challenges such as
              lockdowns and supply shortages, construction of the school
              proceeded, demonstrating remarkable resilience.
            </p>
            <p className="text-lg mb-6">
              Within just six months, the KG section and primary school were
              completed. The school welcomed 184 students at KG and Primary
              levels in 2013, with enrollment rising to 504 students in 2014 EC.
              Employee numbers also grew to 56 in the same year.
            </p>
            <p className="text-lg mb-6">
              Shareholders have shown great determination to realize the
              organization’s vision and mission. Despite the pandemic, the goal
              of establishing a high-standard school was achieved, fulfilling
              all local agency requirements and providing ethical academic
              education.
            </p>

            <div className="border-t border-gray-300 my-6"></div>

            <p className="text-lg mb-4">
              Some significant challenges faced include delays in collecting
              subscribed shares and incomplete documentation. Specific issues
              include:
            </p>
            <ul className="list-disc pl-5 mb-6 space-y-2">
              <li>
                Nearly 40% of subscribed shares are still pending collection
              </li>
              <li>24 members who did not submit their IDs</li>
              <li>
                51 members who did not provide their personal photos for
                documentation
              </li>
              <li>
                38 members who did not sign the memorandum of establishment and
                bylaw
              </li>
              <li>15 members with incomplete files</li>
            </ul>

            <div className="border-t border-gray-300 my-6"></div>

            <p className="text-lg mb-4">
              Borikof's management structure includes:
            </p>
            <ul className="list-disc pl-5 mb-6 space-y-2">
              <li>CEO</li>
              <li>HR In-Charge</li>
              <li>Accountant/Financial Manager</li>
              <li>Store In-Charge</li>
              <li>20 employees working at the kindergarten</li>
              <li>30 employees at the primary school</li>
              <li>6 employees in general services</li>
            </ul>

            <p className="text-lg">
              From a legal standpoint, Borikof operates as a share company,
              adhering to employees’ and shareholders’ rights while fulfilling
              all governmental and social tax obligations.
            </p>
          </div>
        </div>

        <Vision />
      </div>

      {/* Team Members Section */}
      <div className="bg-yellow-50 py-12 mt-12">
        <h2 className="text-5xl font-extrabold text-center text-gray-800 mb-6">
          Meet Our Administrative Team
        </h2>
        <p className="text-center text-gray-700 mb-8 px-8 lg:px-60">
          Our team is composed of dedicated professionals who are passionate
          about providing the best service possible. Each member brings a unique
          set of skills and expertise, making us a diverse and dynamic group.
        </p>

        {/* Administrators Section */}
        <div className="bg-gray-50 py-12">
          <h3 className="text-4xl font-bold text-center text-gray-800 mb-8">
            Administrators
          </h3>
          <div className="flex flex-wrap justify-center px-4 md:px-8 gap-8">
            {staff
              ?.filter((member) => member.role === "administrator")
              .map((member) => (
                <div
                  key={member.id}
                  className="bg-white shadow-lg rounded-lg p-6 w-72 text-center transition-transform transform hover:scale-105 hover:shadow-2xl"
                >
                  <img
                    className="w-28 h-28 rounded-full mx-auto mb-4 border-4 border-teal-400 transition-transform transform hover:scale-110"
                    src={`${member.profile_picture}`}
                    alt={`${member.first_name} ${member.last_name}`}
                  />
                  <h3 className="text-3xl font-semibold text-gray-800 mb-2">{`${member.first_name} ${member.last_name}`}</h3>
                  <p className="text-gray-600 text-lg mb-4">{member.role}</p>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    {member.bio}
                  </p>
                  <button
                    className="mt-2 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition"
                    onClick={() => {
                      setSelectedMember(member);
                      setModalOpen(true);
                    }}
                  >
                    View Profile
                  </button>
                </div>
              ))}
          </div>
        </div>

        {/* Staff Section */}
        <div className="bg-gray-50 py-12 mt-12">
          <h3 className="text-4xl font-bold text-center text-gray-800 mb-8">
            Staff Members
          </h3>
          <div className="flex flex-wrap justify-center px-4 md:px-8 gap-8">
            {staff
              ?.filter((member) => member.role === "staff")
              .map((member) => (
                <div
                  key={member.id}
                  className="bg-white shadow-lg rounded-lg p-6 w-72 text-center transition-transform transform hover:scale-105 hover:shadow-2xl"
                >
                  <img
                    className="w-28 h-28 rounded-full mx-auto mb-4 border-4 border-gray-400 transition-transform transform hover:scale-110"
                    src={`${member.profile_picture}`}
                    alt={`${member.first_name} ${member.last_name}`}
                  />
                  <h3 className="text-3xl font-semibold text-gray-800 mb-2">{`${member.first_name} ${member.last_name}`}</h3>
                  <p className="text-gray-600 text-lg mb-4">{member.role}</p>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    {member.bio}
                  </p>
                  <button
                    className="mt-2 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition"
                    onClick={() => {
                      setSelectedMember(member);
                      setModalOpen(true);
                    }}
                  >
                    View Profile
                  </button>
                </div>
              ))}
          </div>
        </div>

        {/* board members Section */}
        <div className="bg-gray-50 py-12 mt-12">
          <h3 className="text-4xl font-bold text-center text-gray-800 mb-8">
            Board Members
          </h3>
          <div className="flex flex-wrap justify-center px-4 md:px-8 gap-8">
            {staff
              ?.filter((member) => member.role === "board members")
              .map((member) => (
                <div
                  key={member.id}
                  className="bg-white shadow-lg rounded-lg p-6 w-72 text-center transition-transform transform hover:scale-105 hover:shadow-2xl"
                >
                  <img
                    className="w-28 h-28 rounded-full mx-auto mb-4 border-4 border-gray-400 transition-transform transform hover:scale-110"
                    src={`${member.profile_picture}`}
                    alt={`${member.first_name} ${member.last_name}`}
                  />
                  <h3 className="text-3xl font-semibold text-gray-800 mb-2">{`${member.first_name} ${member.last_name}`}</h3>
                  <p className="text-gray-600 text-lg mb-4">{member.role}</p>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    {member.bio}
                  </p>
                  <button
                    className="mt-2 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition"
                    onClick={() => {
                      setSelectedMember(member);
                      setModalOpen(true);
                    }}
                  >
                    View Profile
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Modal for Profile Viewing */}
      {isModalOpen && selectedMember && (
        <Modal member={selectedMember} onClose={() => setModalOpen(false)} />
      )}
    </>
  );
};

export default About;
